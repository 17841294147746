import React from "react";
import { Dialog, DialogContent, DialogTitle, Grid, IconButton, makeStyles, Typography } from "@material-ui/core";
import clsx from "clsx";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
    root: {
        // margin: "auto",
        // padding: theme.spacing(2),
        //width: "150%",
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    logo: {
        width: "200px",
    },
    vertical_spacing: {
        margin: theme.spacing(1, 0),
    },
    subtitle: {
        fontWeight: "Bold",
        fontSize: "1rem",
        lineHeight: 1.75,
        letterSpacing: "0.00938em",
    },
    bold: {
        fontWeight: "Bold",
    },
    italic: {
        fontStyle: "italic",
    },
    nonItalic: {
        fontStyle: "normal",
    },
    logo_div: {
        marginTop: theme.spacing(3),
    },
    end_logo1: {
        width: "28%",
    },
    end_logo2: {
        width: "65%",
    },
}));

export default function TermsFR(props) {
    const classes = useStyles();

    const handleClose = () => {
        props.close(false);
    };

    return (
        <div>
            <Dialog maxWidth="md" aria-labelledby="customized-dialog-title" open={props.openDialog}>
                <DialogTitle id="customized-dialog-title" align="center">
                    <img
                        className={classes.logo}
                        src="/images/Sako+100_logo_since+1921_horizontal_black.png"
                        alt="Sako Logo"
                    />
                    <Typography variant="h5" className={classes.bold}>
                        TIRAGE AU SORT CANADIEN DES CARTOUCHES SAKO ET CONCOURS MONDIAL
                    </Typography>
                    <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>

                <DialogContent dividers>
                    <Typography
                        // className={classes.subtitle}
                        variant="subtitle1"
                    >
                        FORFAIT
                    </Typography>
                    <Typography variant="subtitle2" className={clsx(classes.bold, classes.vertical_spacing)}>
                        Option 1 - Canada Seulement*: <a href="www.sakocanada.ca">www.sakocanada.ca</a>
                    </Typography>
                    <Typography variant="body2" gutterBottom className={classes.vertical_spacing}>
                        Sako Cartridges organise un tirage pour un voyage en Finlande avec une visite de l’usine et du
                        musée Sako. Un gagnant sera sélectionné parmi toutes les participants admissibles. Le vol,
                        l’hébergement et les repas sont inclus.
                    </Typography>
                    <Typography className={clsx(classes.italic, classes.vertical_spacing)} variant="body2" gutterBottom>
                        *Ce tirage est tenu par Stoeger Canada et n’est ouvert qu’aux résidents légaux du Canada.
                    </Typography>
                    <Typography variant="subtitle2" className={clsx(classes.bold, classes.vertical_spacing)}>
                        Option 2 – mondial*:{" "}
                        <a href="http://www.sako.fi/sako100-competition">http://www.sako.fi/sako100-competition</a>
                    </Typography>
                    <Typography variant="body2" gutterBottom className={classes.vertical_spacing}>
                        Le prix principal du billet d’or pour le concours de consommateur cartouches Sako 100 ans est un
                        voyage de chasse en Finlande avec visite guidée de l’usine et du musée Sako. Le vol,
                        l’hébergement et les repas sont inclus. Les autres prix avec des billets Argent sont des
                        casquettes de marque Sako, des étuis de cartouches et des porte-clés.
                    </Typography>
                    <Typography className={clsx(classes.italic, classes.vertical_spacing)} variant="body2" gutterBottom>
                        *Ce concours est organisé par Sako et est ouvert à tous les pays avec un distributeur /revendeur
                        des cartouches Sako.
                    </Typography>
                    <Typography variant="subtitle1" className={classes.vertical_spacing}>
                        COMMENT GAGNER
                    </Typography>
                    <Typography variant="subtitle2" className={clsx(classes.bold, classes.vertical_spacing)}>
                        Le tirage au sort et le concours se terminent le jeudi 31 mars 2022 à 23h59.m.
                    </Typography>
                    <Typography variant="subtitle2" className={clsx(classes.bold, classes.vertical_spacing)}>
                        Option 1 – Tirage au Canada seulement: <a href="www.sakocanada.ca">www.sakocanada.ca</a>
                    </Typography>
                    <Typography
                        variant="body2"
                        gutterBottom
                        className={clsx(classes.vertical_spacing, classes.vertical_spacing)}
                    >
                        Remplissez l’inscription et téléchargez une copie numérique de votre reçu si possible. Le
                        gagnant sera contacté la semaine suivante et aura 48 heures pour répondre. UNE PARTICIPATION PAR
                        JOUR ET PAR E-MAIL.
                    </Typography>
                    <Typography className={clsx(classes.italic, classes.vertical_spacing)} variant="body2" gutterBottom>
                        *Si vous avez des difficultés à remplir le formulaire en ligne, veuillez l’imprimer et l’envoyer
                        par courriel avec une copie numérique de votre reçu à info@stoegercanada.ca.
                    </Typography>
                    <Typography variant="subtitle2" className={clsx(classes.bold, classes.vertical_spacing)}>
                        Option 2 – Billet d’or mondial:{" "}
                        <a href="http://www.sako.fi/sako100-competition">http://www.sako.fi/sako100-competition</a>
                    </Typography>
                    <Typography variant="body2" gutterBottom className={classes.vertical_spacing}>
                        Pour participer, trouvez un billet d’or et/ou d’argent à l’intérieur d’une boîte de cartouche
                        Sako et réclamez votre prix. Chaque billet a un code de sécurité et des instructions pour
                        réclamer votre prix. Les gagnants des billets seront contactés dans un délai d’une semaine. Les
                        prix seront livrés par la poste.
                    </Typography>

                    <Typography variant="subtitle1" className={classes.vertical_spacing}>
                        ÉLIGIBILITÉ
                    </Typography>
                    <Typography variant="body2" gutterBottom className={classes.vertical_spacing}>
                        Doit être âgé de plus de 18 ans avec un passeport valide, un permis de possession et
                        d’acquisition (P.P.A..), et un permit de chasse. Si le gagnant ne détient pas de P.P.A.. et
                        permit de chasse valide, il ne pourra pas réclamer le prix.
                    </Typography>
                    <Typography variant="subtitle2" gutterBottom className={classes.vertical_spacing}>
                        <u>Option 1 – Canada seulement:</u> Le gagnant doit être un résident légal du Canada.
                    </Typography>
                    <Typography variant="body2" gutterBottom className={classes.vertical_spacing}>
                        CONSOMMATEUR SEULEMENT. Les employés de Stoeger Canada, les représentants des ventes, les
                        concessionnaires autorisés et leur personnel de vente, ainsi que les membres de leur famille
                        immédiate ne sont pas admissibles à cette promotion.{" "}
                    </Typography>
                    <Typography variant="body2" gutterBottom className={classes.vertical_spacing}>
                        Les prix sont gracieuseté de Sako et Stoeger Canada. Le forfait doit être accepté tel quel. NON
                        transférable et sans valeur monétaire. Si le gagnant refuse le prix, le commanditaire du
                        concours est libéré de toute obligation liée au forfait.{" "}
                    </Typography>
                    <Typography className={clsx(classes.bold, classes.vertical_spacing)} variant="body2" gutterBottom>
                        Les commanditaires du concours conservent le droit, à leur entière discrétion, d’effectuer des
                        substitutions de nature ou de valeur équivalentes en cas d’indisponibilité de tout élément du
                        forfait pour quelque raison que ce soit. Le gagnant du concours peut être tenu de récupérer le
                        forfait à un endroit qui convient au gagnant et aux commanditaires du concours ou de couvrir les
                        frais d’expédition du forfait à l’emplacement du gagnant.
                    </Typography>
                    <Typography variant="subtitle1" className={classes.vertical_spacing}>
                        TERMES & CONDITIONS
                    </Typography>
                    <Typography variant="body2" gutterBottom className={classes.vertical_spacing}>
                        Toutes les soumissions deviennent la propriété des commanditaires du concours (Stoeger Canada et
                        Sako). Vous trouverez des renseignements sur les principes de protection des données pour
                        Stoeger Canada à <a href="www.sakocanada.ca">www.sakocanada.ca</a> ou pour Sako à{" "}
                        <a href="http://www.sako.fi/sako100-competition">http://www.sako.fi/sako100-competition</a>.
                    </Typography>
                    <Typography className={clsx(classes.bold, classes.vertical_spacing)} variant="subtitle2">
                        CONDITIONS D’OBTENTION
                    </Typography>
                    <Typography variant="body2" gutterBottom className={classes.vertical_spacing}>
                        Les entrées doivent être inscrits et reçus avant la date de clôture du concours, jeudi 31
                        mars,2022,à 23h59.
                    </Typography>
                    <Typography variant="body2" gutterBottom className={classes.vertical_spacing}>
                        Aucun achat n’est nécessaire cependant, tous les champs pour le tirage doivent être rempli dans
                        son intégralité. Les envois en nombre, les reproductions ou les articles protégés par le droit
                        d’auteur ne sont pas admissibles. Nul là où la loi l’interdit.
                    </Typography>
                    <Typography variant="body2" gutterBottom className={classes.vertical_spacing}>
                        Pour être sélectionnés comme gagnants, les participants doivent se conformer aux conditions
                        suivantes : 1) avoir lu et respecté le règlement du concours; et 2) signer un formulaire
                        standard de déclaration et de décharge confirmant la conformité au règlement du concours,
                        l’acceptation de leur prix tel qu’attribué et libérant les commanditaires du concours et leurs
                        sociétés affiliées et chacun de leurs employés, dirigeants, administrateurs et agents respectifs
                        et leurs agences de publicité et de promotion respectives de tout dommage ou perte pouvant
                        résulter de la participation à ce concours ou de l’acceptation et/ou de l’utilisation d’un prix.
                    </Typography>
                    <Typography variant="body2" gutterBottom className={classes.vertical_spacing}>
                        Les participants au concours conviennent que les commanditaires du concours et leurs sociétés
                        affiliées, ainsi que chacun de leurs employés, dirigeants, administrateurs et mandataires
                        respectifs, ne seront aucunement tenus responsables des réclamations, dommages, pertes et coûts,
                        peu importe ceux découlant de la participation du participant ou du gagnant à, ou de
                        l’attribution ou de l’utilisation des produits des commanditaires du concours dans le cadre de
                        ce concours.
                    </Typography>
                    <Typography className={classes.bold} variant="subtitle2" gutterBottom>
                        CONDITIONS GÉNÉRALES
                    </Typography>
                    <Typography variant="body2" gutterBottom className={classes.vertical_spacing}>
                        Les commanditaires du concours et leurs sociétés affiliées, agences de publicité et de promotion
                        respectives n’acceptent aucune responsabilité dans tous les cas où leur incapacité à satisfaire
                        au présent règlement du concours découle d’une situation qui est hors de leur contrôle, y
                        compris, mais sans s’y limiter, une situation de grève, de lock-out ou d’autres conflits de
                        travail dans les lieux de travail des commanditaires ou dans les lieux de travail de toute
                        organisation ou entité dont les services sont impliqués dans le déroulement de ce concours.{" "}
                    </Typography>
                    <Typography variant="body2" gutterBottom className={classes.vertical_spacing}>
                        Les commanditaires du concours n’acceptent aucune responsabilité pour les participations
                        perdues, mal acheminées, incomplètes, retardées ou détruites ou pour la défaillance ou le
                        mauvais fonctionnement de tout matériel, logiciel ou composant de télécommunication lié aux
                        participations en ligne. Les commanditaires du concours ont le droit de mettre fin ou de
                        suspendre ce concours ou de modifier le règlement du concours en tout temps et sans préavis pour
                        quelque raison que ce soit.
                    </Typography>
                    <Typography variant="body2" gutterBottom className={classes.vertical_spacing}>
                        Toutes les participations deviennent la propriété des commanditaires du concours, qui n’assument
                        aucune responsabilité en cas de perte, de vol, de retard, d’endommagement ou de détournement de
                        participation ou de toute défaillance du site Web pendant la période promotionnelle, de tout
                        problème ou dysfonctionnement technique de tout réseau ou ligne téléphonique, système
                        informatique en ligne, serveur, fournisseur d’accès, équipement informatique, logiciel,
                        défaillance de tout courriel en raison de problèmes techniques ou de congestion du trafic sur
                        Internet ou sur tout site Web. , ou toute combinaison de ceux-ci, y compris tout bris ou dommage
                        à l’ordinateur d’un participant ou de toute autre personne lié à ou résultant de la lecture ou
                        du téléchargement de tout matériel de la promotion. Toute tentative d’endommager délibérément un
                        site Web ou de nuire au fonctionnement légitime de cette promotion constitue une violation des
                        lois criminelles et civiles, et si une telle tentative est faite, les commanditaires du concours
                        se réservent le droit de demander des recours et des dommages-intérêts dans toute la mesure
                        permise par la loi, y compris des poursuites criminelles.
                    </Typography>
                    <Typography variant="body2" gutterBottom className={classes.vertical_spacing}>
                        En s’inscrivant au concours, les gagnants sélectionnés acceptent de se conformer au présent
                        règlement officiel du concours et acceptent la publication, la reproduction et/ou toute autre
                        utilisation de son nom, de son adresse, de sa voix, de ses déclarations au sujet du concours
                        et/ou de sa photographie personnelle ou de toute autre ressemblance, sans autre compensation,
                        dans toute publicité ou publicité effectuée par le commanditaire de quelque manière que ce soit,
                        y compris sur papier, en diffusion ou en mode numérique.
                    </Typography>
                    <Typography variant="body2" gutterBottom className={classes.vertical_spacing}>
                        Les participations sont sujettes à vérification et seront déclarées invalides si elles sont
                        illisibles, reproduites mécaniquement, mutilées, contrefaites, falsifiées, modifiées ou altérées
                        de quelque manière que ce soit. De fausses informations rendent la demande de remboursement
                        nulle. Les contrevenants peuvent être poursuivis. Les entrants acceptent de se conformer au
                        règlement du concours et aux décisions du jury indépendant, qui sont finales.
                    </Typography>

                    <Typography
                        className={clsx(classes.bold, classes.vertical_spacing)}
                        variant="subtitle2"
                        gutterBottom
                    >
                        Option 1 – Tirage au Canada seulement: <a href="www.sakocanada.ca">www.sakocanada.ca</a>
                    </Typography>
                    <Typography variant="body2" gutterBottom className={classes.vertical_spacing}>
                        Le gagnant sera choisi au hasard et est assujetti à toutes les lois fédérales, provinciales et
                        municipales applicables. Aucune correspondance ne sera échangée, sauf avec les participants
                        sélectionnés et les personnes qui demandent une copie des règles. Les chances qu’une
                        participation soit sélectionnée dépendent du nombre et de la qualité des participations
                        admissibles reçues avant la date de clôture du concours. Toutes les participations deviennent la
                        propriété des commanditaires du concours. Les commanditaires du concours peuvent rendre les
                        renseignements figurant sur ces formulaires de participation accessibles à des fins
                        promotionnelles, à moins que les participants ne demandent le contraire.
                    </Typography>
                    <Typography
                        className={clsx(classes.bold, classes.vertical_spacing)}
                        variant="subtitle2"
                        gutterBottom
                    >
                        Option 2 - mondial:{" "}
                        <a href="http://www.sako.fi/sako100-competition">http://www.sako.fi/sako100-competition</a>
                    </Typography>
                    <Typography variant="body2" gutterBottom className={classes.vertical_spacing}>
                        Voir le site Web pour plus de détails.
                    </Typography>
                    <Grid container direction="row" justify="space-between" className={classes.logo_div}>
                        <Grid item md={6} sm={6} xs={6}>
                            <img
                                className={classes.end_logo1}
                                src="/images/logo_demand_perfection.png"
                                alt="Sako Demand Perfection Logo"
                            />
                        </Grid>
                        <Grid item md={6} sm={6} xs={6} align="right">
                            <img className={classes.end_logo2} src="/images/Stoeger_logo.png" alt="Stoeger Logo" />
                        </Grid>
                    </Grid>
                    <Typography
                        className={clsx(classes.vertical_spacing, classes.logo_div)}
                        align="right"
                        variant="body2"
                        gutterBottom
                    >
                        © 2021 Stoeger Canada(1990) Ltd.
                    </Typography>
                </DialogContent>
            </Dialog>
        </div>
    );
}
