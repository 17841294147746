import React from "react";
import { Dialog, DialogContent, DialogTitle, Grid, IconButton, makeStyles, Typography } from "@material-ui/core";
import clsx from "clsx";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
    root: {
        // margin: "auto",
        // padding: theme.spacing(2),
        //width: "150%",
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    logo: {
        width: "200px",
    },
    vertical_spacing: {
        margin: theme.spacing(1, 0),
    },
    subtitle: {
        fontWeight: "Bold",
        fontSize: "1rem",
        lineHeight: 1.75,
        letterSpacing: "0.00938em",
    },
    bold: {
        fontWeight: "Bold",
    },
    italic: {
        fontStyle: "italic",
    },
    nonItalic: {
        fontStyle: "normal",
    },
    logo_div: {
        marginTop: theme.spacing(3),
    },
    end_logo1: {
        width: "28%",
    },
    end_logo2: {
        width: "65%",
    },
}));

export default function Terms(props) {
    const classes = useStyles();

    const handleClose = () => {
        props.close(false);
    };

    return (
        <div>
            <Dialog maxWidth="md" aria-labelledby="customized-dialog-title" open={props.openDialog}>
                <DialogTitle id="customized-dialog-title" align="center">
                    <img
                        className={classes.logo}
                        src="/images/Sako+100_logo_since+1921_horizontal_black.png"
                        alt="Sako Logo"
                    />
                    <Typography variant="h5" className={classes.bold}>
                        SAKO CARTRIDGES CANADIAN DRAW & WORLDWIDE CONTEST
                    </Typography>
                    <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>

                <DialogContent dividers>
                    <Typography
                        // className={classes.subtitle}
                        variant="subtitle1"
                    >
                        PRIZE PACKAGE
                    </Typography>
                    <Typography variant="subtitle2" className={clsx(classes.bold, classes.vertical_spacing)}>
                        Option 1 - Canada Only*: <a href="www.sakocanada.ca">www.sakocanada.ca</a>
                    </Typography>
                    <Typography variant="body2" gutterBottom className={classes.vertical_spacing}>
                        Sako Cartridges draw for a hunting trip to Finland with a tour of the Sako Factory and museum.
                        One winner will be selected from all eligible entries. Flight, accommodations, and meals are
                        included.
                    </Typography>
                    <Typography className={clsx(classes.italic, classes.vertical_spacing)} variant="body2" gutterBottom>
                        *This draw is held by Stoeger Canada and only open to legal residents of Canada.
                    </Typography>
                    <Typography variant="subtitle2" className={clsx(classes.bold, classes.vertical_spacing)}>
                        Option 2 – Worldwide*:{" "}
                        <a href="http://www.sako.fi/sako100-competition">http://www.sako.fi/sako100-competition</a>
                    </Typography>
                    <Typography variant="body2" gutterBottom className={classes.vertical_spacing}>
                        The Golden ticket main prize for the Sako Cartridges 100 years consumer competition is a hunting
                        trip to Finland with tour to visit the Sako Factory and museum. Flight, accommodations, and
                        meals are included. Other prizes with Silver tickets are Sako branded caps, cartridge stock
                        holsters and headstamp key rings.
                    </Typography>
                    <Typography className={clsx(classes.italic, classes.vertical_spacing)} variant="body2" gutterBottom>
                        *This contest is held by Sako and is open to all countries with a Sako Cartridges
                        distributor/dealer.
                    </Typography>
                    <Typography variant="subtitle1" className={classes.vertical_spacing}>
                        HOW TO WIN
                    </Typography>
                    <Typography variant="subtitle2" className={clsx(classes.bold, classes.vertical_spacing)}>
                        Draw and Contest closes Thursday, March 31st, 2022, at 23:59p.m.
                    </Typography>
                    <Typography variant="subtitle2" className={clsx(classes.bold, classes.vertical_spacing)}>
                        Option 1 - Canada Only Draw: <a href="www.sakocanada.ca">www.sakocanada.ca</a>
                    </Typography>
                    <Typography
                        variant="body2"
                        gutterBottom
                        className={clsx(classes.vertical_spacing, classes.vertical_spacing)}
                    >
                        Fill out the entry registration and upload a digital copy of your receipt if possible. The
                        winner will be contacted the following week and given 48hrs to reply. ONE ENTRY PER DAY PER
                        EMAIL.
                    </Typography>
                    <Typography className={clsx(classes.italic, classes.vertical_spacing)} variant="body2" gutterBottom>
                        *If you have any difficulties filling out the form online, please print the form and email it
                        with a digital copy of your receipt to info@stoegercanada.ca.
                    </Typography>
                    <Typography variant="subtitle2" className={clsx(classes.bold, classes.vertical_spacing)}>
                        Option 2 - Worldwide Golden Ticket:{" "}
                        <a href="http://www.sako.fi/sako100-competition">http://www.sako.fi/sako100-competition</a>
                    </Typography>
                    <Typography variant="body2" gutterBottom className={classes.vertical_spacing}>
                        To participate, find a Golden and/or Silver ticket inside a Sako Cartridge box and claim your
                        prize. Every ticket has a security code and instructions to claim your prize. Ticket winners
                        will be contacted within one week. Commodity prizes will be delivered by mail.
                    </Typography>

                    <Typography variant="subtitle1" className={classes.vertical_spacing}>
                        ELIGIBILITY
                    </Typography>
                    <Typography variant="body2" gutterBottom className={classes.vertical_spacing}>
                        Must be over the age of 18 years with a valid passport, Possession and Acquisition License
                        (P.A.L.) and hunting license. If the winner does not hold a valid P.A.L. and hunting license
                        he/she will not be able to claim the prize.
                    </Typography>
                    <Typography variant="subtitle2" gutterBottom className={classes.vertical_spacing}>
                        <u>Option 1 - Canada Only:</u> Winner must be a legal resident of Canada.
                    </Typography>
                    <Typography variant="body2" gutterBottom className={classes.vertical_spacing}>
                        CONSUMER ONLY. Stoeger Canada employees, sales representatives, authorized dealers and their
                        sales staff, and members of their immediate families are not eligible for this promotion.
                    </Typography>
                    <Typography variant="body2" gutterBottom className={classes.vertical_spacing}>
                        Prizes are courtesy of Sako and Stoeger Canada. The prize package must be accepted as awarded.
                        NON-Transferrable and No Cash Value. Should the winner refuse the prize, the Contest Sponsor is
                        released from any obligation related to the prize package.
                    </Typography>
                    <Typography className={clsx(classes.bold, classes.vertical_spacing)} variant="body2" gutterBottom>
                        The Contest Sponsors retain the right in their absolute discretion to make substitutions of
                        equivalent kind or value in the event of the unavailability of any element of the prize package
                        for any reason whatsoever. The contest winner may be required to pick up the prize package at a
                        location agreeable to the winner and Contest Sponsors or cover the cost of shipping the prize
                        package to the winner’s location.
                    </Typography>
                    <Typography variant="subtitle1" className={classes.vertical_spacing}>
                        TERMS & CONDITIONS
                    </Typography>
                    <Typography variant="body2" gutterBottom className={classes.vertical_spacing}>
                        All submissions become the property of the Contest Sponsors (Stoeger Canada and Sako).
                        Information about data protection principles for Stoeger Canada can be found at{" "}
                        <a href="www.sakocanada.ca">www.sakocanada.ca</a> or for Sako at{" "}
                        <a href="http://www.sako.fi/sako100-competition">http://www.sako.fi/sako100-competition</a>.
                    </Typography>
                    <Typography className={clsx(classes.bold, classes.vertical_spacing)} variant="subtitle2">
                        CONDITIONS OF WINNING
                    </Typography>
                    <Typography variant="body2" gutterBottom className={classes.vertical_spacing}>
                        Entries must be registered and received by the contest closing date, Thursday, March 31st, 2022,
                        at 23:59pm.
                    </Typography>
                    <Typography variant="body2" gutterBottom className={classes.vertical_spacing}>
                        No purchase is necessary however, all fields for the draw must be completed in full. Bulk
                        mailings, reproductions or copyrighted items are ineligible. Void where prohibited by law.
                    </Typography>
                    <Typography variant="body2" gutterBottom className={classes.vertical_spacing}>
                        To be selected as a winner, entrants must comply with the following conditions: 1) have read and
                        complied with the contest rules; and 2) sign a standard declaration and release form confirming
                        compliance with the contest rules, acceptance of their prize as awarded and releasing the
                        Contest Sponsors and their affiliated companies and each of their respective employees,
                        officers, directors and agents and their respective advertising and promotional agencies from
                        any damage or loss that may result from entering this contest or accepting and/or utilizing a
                        prize.
                    </Typography>
                    <Typography variant="body2" gutterBottom className={classes.vertical_spacing}>
                        Contest entrants agree that the Contest Sponsors and their affiliated companies and each of
                        their respective employees, officers, directors and agents, shall in no way be liable for any
                        claims, damages, losses, costs, however arising as a result of the entrant’s or winner’s
                        participation in, or the award or use of the Contest Sponsors’ products pursuant to this
                        contest.
                    </Typography>
                    <Typography className={classes.bold} variant="subtitle2" gutterBottom>
                        GENERAL CONDITIONS
                    </Typography>
                    <Typography variant="body2" gutterBottom className={classes.vertical_spacing}>
                        The Contest Sponsors and their respective affiliates, advertising, and promotional agencies,
                        accept no responsibility in any case where their inability to satisfy these contest rules arises
                        from any situation which is outside of their control, including but not limited to a situation
                        of strike, lock-out or other labour strife in the Sponsors’ workplaces or in the workplaces of
                        any organization or entity whose services are involved in running this contest.
                    </Typography>
                    <Typography variant="body2" gutterBottom className={classes.vertical_spacing}>
                        The Contest Sponsors accept no responsibility for entries lost, misdirected, incomplete, delayed
                        or destroyed or for the failure or malfunction of any hardware, software or telecommunication
                        component relating to online entries. The Contest Sponsors have the right to terminate or
                        suspend this contest or to modify the contest rules at any time without notice for any reason
                        whatsoever.
                    </Typography>
                    <Typography variant="body2" gutterBottom className={classes.vertical_spacing}>
                        All entries become the property of the Contest Sponsors, who assume no responsibility for lost,
                        stolen, delayed, damaged or misdirected entries or for any failure of the website during the
                        promotional period, for any problems or technical malfunction of any telephone network or lines,
                        computer on-line systems, servers, access providers, computer equipment, software, failure of
                        any e-mail on account of technical problems or traffic congestion on the Internet or at any
                        website, or any combination thereof including any injury or damage to an entrant’s or any other
                        person’s computer related to or resulting from playing or downloading any material in the
                        promotion. Any attempt to deliberately damage any website or to undermine the legitimate
                        operation of this promotion is a violation of criminal and civil laws, and should such an
                        attempt be made, the Contest Sponsors reserve the right to seek remedies and damages to the
                        fullest extent permitted by law, including criminal prosecution.
                    </Typography>
                    <Typography variant="body2" gutterBottom className={classes.vertical_spacing}>
                        By entering the contest selected winners agree to comply with these official contest rules and
                        agree to the publication, reproduction, and/or other use of his/her name, address, voice,
                        statements about the contest, and/or personal photograph or other likeness without further
                        compensation, in any publicity or advertising carried out by the Sponsor in any manner
                        whatsoever, including print, broadcast, or digital.
                    </Typography>
                    <Typography variant="body2" gutterBottom className={classes.vertical_spacing}>
                        Entries are subject to verification and will be declared invalid if they are illegible,
                        mechanically reproduced, mutilated, forged, falsified, altered, or tampered with in any way.
                        False information renders the rebate submission void. Violators may be prosecuted. Entrants
                        agree to abide by the Contest Rules and the decisions of the independent judging panel, which
                        are final.
                    </Typography>

                    <Typography
                        className={clsx(classes.bold, classes.vertical_spacing)}
                        variant="subtitle2"
                        gutterBottom
                    >
                        Option 1 - Canada Only Draw: <a href="www.sakocanada.ca">www.sakocanada.ca</a>
                    </Typography>
                    <Typography variant="body2" gutterBottom className={classes.vertical_spacing}>
                        The winner will be chosen completely at random and is subject to all applicable federal,
                        provincial and municipal laws. No correspondence will be entered into except with the selected
                        entrants and individuals requesting a copy of the rules. The odds of an entry being selected
                        depend on the number and quality of eligible entries received by the contest closing date. All
                        entries, become the property of the Contest Sponsors. The Contest Sponsors may make the
                        information on these entry forms available for promotional purposes unless entrants request
                        otherwise.
                    </Typography>
                    <Typography
                        className={clsx(classes.bold, classes.vertical_spacing)}
                        variant="subtitle2"
                        gutterBottom
                    >
                        Option 2 - Worldwide:{" "}
                        <a href="http://www.sako.fi/sako100-competition">http://www.sako.fi/sako100-competition</a>
                    </Typography>
                    <Typography variant="body2" gutterBottom className={classes.vertical_spacing}>
                        See website for further details.
                    </Typography>
                    <Grid container direction="row" justify="space-between" className={classes.logo_div}>
                        <Grid item md={6} sm={6} xs={6}>
                            <img
                                className={classes.end_logo1}
                                src="/images/logo_demand_perfection.png"
                                alt="Sako Demand Perfection Logo"
                            />
                        </Grid>
                        <Grid item md={6} sm={6} xs={6} align="right">
                            <img className={classes.end_logo2} src="/images/Stoeger_logo.png" alt="Stoeger Logo" />
                        </Grid>
                    </Grid>
                    <Typography
                        className={clsx(classes.vertical_spacing, classes.logo_div)}
                        align="right"
                        variant="body2"
                        gutterBottom
                    >
                        © 2021 Stoeger Canada(1990) Ltd.
                    </Typography>
                </DialogContent>
            </Dialog>
        </div>
    );
}
