import React, { useState } from "react";
import { Button, Card, Divider, Grid, Link as MLink, makeStyles, Typography } from "@material-ui/core";
import Footer from "../component/Footer";
import Header from "../component/Header";
import { Link } from "react-router-dom";
import Terms from "./Terms";
import TermsFR from "./TermsFR";

const useStyles = makeStyles((theme) => ({
    background: {
        backgroundImage: "url(/images/JPG+Large_Kitka_river_2018.jpg)",
        backgroundRepeat: "no-repeat",
        backgroundColor: theme.palette.grey[50],
        backgroundSize: "cover",
        backgroundPosition: "center",
    },
    header: { padding: theme.spacing(3) },
    card: {
        background: "rgba(255, 255, 255, 0.7)",
        width: "80%",
        marginTop: theme.spacing(3),
        paddingTop: theme.spacing(2),
        [theme.breakpoints.only("sm")]: {
            width: "95%",
        },
        [theme.breakpoints.only("xs")]: {
            width: "100%",
            marginTop: theme.spacing(0),
        },
    },
    container: {
        width: "75%",
        margin: "auto",
        padding: theme.spacing(2, 0),
        [theme.breakpoints.only("sm")]: {
            width: "95%",
        },
        [theme.breakpoints.only("xs")]: {
            width: "100%",
        },
    },
    divider: {
        background: "black",
        margin: theme.spacing(6, "auto"),
        width: "50%",
    },
    body: {
        marginTop: theme.spacing(6),
    },
    button: {
        margin: theme.spacing(6, "auto"),
    },
    btn: {
        width: "25%",
        // color: "white",
    },
    terms: {
        width: "50px",
    },
}));

export default function Home() {
    const classes = useStyles();
    const [terms, setTerms] = useState(false);
    const [termsfr, setTermsfr] = useState(false);

    return (
        <div>
            <Header />
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justify="center"
                className={classes.background}
                style={{ minHeight: "100vh" }}
            >
                <Card className={classes.card}>
                    <div
                        style={{
                            backgroundColor: "#f06c04",
                            color: "white",
                            fontSize: "18px",
                            width: "100%",
                            padding: "20px",
                            textAlign: "center",
                        }}
                    >
                        Contest Closed - Winner to be announced soon.
                    </div>
                    <div className={classes.container}>
                        <div>
                            <Typography variant="h2" align="center">
                                Canadian Golden Ticket Draw
                            </Typography>
                            <Typography variant="h4" align="center">
                                Sako Ammunition 100th Anniversary
                            </Typography>
                        </div>
                        <div className={classes.body}>
                            <Typography variant="subtitle1" align="center" style={{ fontSize: "25px" }} gutterBottom>
                                Enter the draw to win an all expenses paid hunting trip to Finland in 2022.
                            </Typography>
                            <Typography variant="subtitle1" align="center" style={{ fontSize: "25px" }}>
                                For Canadian Residence Only. Must be 18 years of age or more with a valid P.A.L.,
                                passport and hunting license. Void where prohibited by law. Please read the{" "}
                                <MLink
                                    onClick={() => setTerms(true)}
                                    style={{
                                        // color: "#ffffff",
                                        textDecoration: "underline",
                                    }}
                                >
                                    terms and conditions
                                </MLink>
                                .
                            </Typography>
                        </div>
                    </div>
                    <Divider className={classes.divider} />{" "}
                    <div
                        style={{
                            backgroundColor: "#f06c04",
                            color: "white",
                            fontSize: "18px",
                            width: "100%",
                            padding: "20px",
                            textAlign: "center",
                        }}
                    >
                        Concours terminé - Le gagnant sera annoncé bientôt.
                    </div>
                    <div className={classes.container}>
                        <div>
                            <Typography variant="h2" align="center">
                                Tirage Canadien au sort Golden Ticket
                            </Typography>
                            <Typography variant="h4" align="center">
                                Sako Ammunition 100e anniversaire
                            </Typography>
                        </div>
                        <div className={classes.body}>
                            <Typography variant="subtitle1" align="center" style={{ fontSize: "25px" }} gutterBottom>
                                Participez au tirage et gagner un voyage de chasse tous frais payés en Finlande en 2022.
                            </Typography>
                            <Typography variant="subtitle1" align="center" style={{ fontSize: "25px" }}>
                                Résidence Canadienne seulement. Doit être âgé de 18 ans ou plus avec un permis P.P.A.,
                                un permis de chasse, et un passeport valide. Nul lorsqu’il est interdit et soumis à
                                toutes les lois. Veuillez lire les{" "}
                                <MLink onClick={() => setTermsfr(true)} style={{ textDecoration: "underline" }}>
                                    termes et conditions
                                </MLink>
                                .
                            </Typography>
                        </div>
                    </div>
                    <div align="center" component={Button} className={classes.button}>
                        <Button
                            disabled
                            variant="contained"
                            color="primary"
                            className={classes.btn}
                            component={Link}
                            to="/registration"
                        >
                            <div style={{ display: "flex", flexDirection: "column" }}>
                                <div style={{ fontSize: "18px" }}>Enter Now</div>
                                <div style={{ fontSize: "10px" }}>Canada Only / Seulement au Canada</div>
                            </div>
                        </Button>
                    </div>
                    <div align="center" className={classes.button}>
                        <a
                            style={{ color: "#000", fontSize: "16px", fontWeight: "Bold" }}
                            href="https://www.sako.fi/sako100-competition"
                        >
                            SAKO'S INTERNATIONAL GOLDEN TICKET
                        </a>
                    </div>
                    <div align="center" className={classes.button}>
                        {/* <Button component="a" href="https://www.stoegercanada.ca/brands/sako/">
                            Return to Sako Canada
                        </Button> */}
                        <a
                            style={{ color: "#000", textDecoration: "none", textTransform: "uppercase" }}
                            href="https://www.stoegercanada.ca/brands/sako/"
                        >
                            Return to Sako Canada
                        </a>
                    </div>
                </Card>
                <Footer />
            </Grid>
            <Terms className={classes.terms} openDialog={terms} close={(c) => setTerms(false)} />
            <TermsFR className={classes.terms} openDialog={termsfr} close={(c) => setTermsfr(false)} />
        </div>
    );
}
