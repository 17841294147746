import React from "react";
import { AppBar } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { useHistory } from "react-router";
const useStyles = makeStyles(() => ({
    header: {
        backgroundColor: "#000000",
        //background: "transparent",
        boxShadow: "none",
    },
    headerImage: {
        width: "8%",
        display: "block",
        margin: "20px 20px",
    },
}));
export default function Header(props) {
    const classes = useStyles();
    const history = useHistory();

    const handleRoute = () => {
        history.push("/");
    };

    return (
        <div>
            <AppBar className={classes.header} position="static">
                {/* <a href=""> */}
                <img
                    onClick={handleRoute}
                    className={classes.headerImage}
                    src="/images/Sako+100_logo_since+1921_horizontal_white.png"
                    alt="Beretta"
                />
                {/* </a> */}
            </AppBar>
        </div>
    );
}
