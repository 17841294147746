import React from "react";
import { Divider, Grid, List, ListItem, makeStyles, Typography } from "@material-ui/core";
import Header from "../component/Header";
import Footer from "../component/Footer";

const useStyles = makeStyles((theme) => ({
    background: {
        backgroundImage: "url(/images/404.jpg)",
        backgroundRepeat: "no-repeat",
        backgroundColor: theme.palette.grey[50],
        backgroundSize: "cover",
        backgroundPosition: "center",
    },
    message: {
        margin: "25vh auto",
        color: "white",
    },
    divider: {
        background: "white",
        width: "25%",
        margin: theme.spacing(4, "auto"),
    },
}));

export default function Error404() {
    const classes = useStyles();

    return (
        <div>
            <Header />
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                className={classes.background}
                style={{ minHeight: "100vh" }}
            >
                <div className={classes.message}>
                    <List>
                        <ListItem>
                            <div>
                                <Typography variant="h4">Error 404: Page Cannot be Found</Typography>
                                <Typography variant="subtitle1" style={{ marginTop: "20px" }}>
                                    Please verify that the url is typed correctly.
                                </Typography>
                            </div>
                        </ListItem>
                    </List>
                    <Divider classes={{ root: classes.divider }} />
                    <List>
                        <ListItem>
                            <div>
                                <Typography variant="h4">Erreur 404: Page Est Introuvable</Typography>
                                <Typography variant="subtitle1" style={{ marginTop: "20px" }}>
                                    Veuillez vérifier que l'URL est correctement saisie.
                                </Typography>
                            </div>
                        </ListItem>
                    </List>
                </div>

                <Footer />
            </Grid>
        </div>
    );
}
